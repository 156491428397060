import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/app/app/_components/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/NavLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/ThemeSwitcher.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/app/_components/Nav.module.css");
;
import(/* webpackMode: "eager" */ "/app/app/layout.module.css");
;
import(/* webpackMode: "eager" */ "/app/assets/android-chrome-192x192.png");
;
import(/* webpackMode: "eager" */ "/app/assets/android-chrome-384x384.png");
;
import(/* webpackMode: "eager" */ "/app/assets/apple-touch-icon.png");
;
import(/* webpackMode: "eager" */ "/app/assets/face.png");
;
import(/* webpackMode: "eager" */ "/app/assets/favicon-16x16.png");
;
import(/* webpackMode: "eager" */ "/app/assets/favicon-32x32.png");
;
import(/* webpackMode: "eager" */ "/app/assets/mstile-150x150.png");
;
import(/* webpackMode: "eager" */ "/app/content/curl/curl-svgrepo-com.svg");
;
import(/* webpackMode: "eager" */ "/app/content/github/github-142-svgrepo-com.svg");
;
import(/* webpackMode: "eager" */ "/app/content/html/html-124-svgrepo-com.svg");
;
import(/* webpackMode: "eager" */ "/app/content/linux/linux-svgrepo-com.svg");
;
import(/* webpackMode: "eager" */ "/app/content/react/react-svgrepo-com.svg");
;
import(/* webpackMode: "eager" */ "/app/content/systemd/light-systemd-svgrepo-com.svg");
;
import(/* webpackMode: "eager" */ "/app/content/tech/computer-svgrepo-com.svg");
;
import(/* webpackMode: "eager" */ "/app/content/tech/creating-a-breakout-game-again/thumbnail.png");
;
import(/* webpackMode: "eager" */ "/app/content/tech/creating-a-breakout-game/thumbnail.png");
;
import(/* webpackMode: "eager" */ "/app/content/tech/github-backup/files.png");
;
import(/* webpackMode: "eager" */ "/app/content/things/filament-tag/IMG_20210107_140532525.jpg");
;
import(/* webpackMode: "eager" */ "/app/content/typescript/typescript-svgrepo-com.svg");
;
import(/* webpackMode: "eager" */ "/app/content/webpack/webpack-svgrepo-com.svg");
;
import(/* webpackMode: "eager" */ "/app/content/wireguard/wireguard-svgrepo-com.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Noto_Sans\",\"arguments\":[{\"weight\":[\"400\",\"600\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"font\"}");
